.single-adventure {
  position: relative;
}

.single-adventure .preloader {
  padding: 150px 0;
}

.single-adventure .book-now {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
}

.single-adventure .embedded-video {
  max-width: 800px;
  margin: 0 auto;
}

.single-adventure .embedded-video iframe {
  width: 90%;
  height: 30em;
}

@media (max-width: 768px) {
  .single-adventure .embedded-video iframe {
    height: 12em;
  }
}

.call-to-action {
  background: #ef3b24;
  padding: 20px 0;
}

.call-to-action h3 {
  margin: 0;
  padding: 8px 20px;
  vertical-align: middle;
  line-height: 25px;
  display: inline-block;
  font-size: 22px;
  text-transform: none;
  color: #fff;
}

.call-to-action .button {
  background: #fdbf12;
  border: 2px solid #fdbf12;
  color: #0b1828;
  text-shadow: none;
  font-family: "MuseoSansCyrl-900",sans-serif;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
}

.call-to-action .button:hover {
  text-decoration: none;
  background: #fff;
  border-color: #000;
}

.trip-info-section {
  z-index: 99;
  position: fixed;
  top: 85px;
  left: 0;
  height: 40px;
  width: 100%;
  background: #0c1828;
  padding: 0 30px;
}

.trip-info-section.top-hidden {
  top: 0;
}

.trip-info-section .trip-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.trip-info-section .trip-nav .table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trip-info-section .trip-nav .trip-info-icons,
.trip-info-section .trip-nav .trip-sections {
  font-size: 14px;
  color: #fff;
  vertical-align: middle;
}

.trip-info-section .trip-nav .trip-info-icons ul,
.trip-info-section .trip-nav .trip-sections ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.trip-info-section .trip-nav .trip-info-icons i,
.trip-info-section .trip-nav .trip-sections i {
  color: #febe14;
  font-size: 17px;
  margin-right: 5px;
}

.trip-info-section .trip-nav .trip-info-icons.trip-info-icons,
.trip-info-section .trip-nav .trip-sections.trip-info-icons {
  min-width: 614px;
  padding: 0 20px;
}

.trip-info-section .trip-nav .trip-info-icons.trip-info-icons li,
.trip-info-section .trip-nav .trip-sections.trip-info-icons li {
  display: inline-block;
  padding: 0 5px;
}

.trip-info-section .trip-nav .trip-info-icons.trip-info-icons li.title-wrapper,
.trip-info-section .trip-nav .trip-sections.trip-info-icons li.title-wrapper {
  height: 40px;
  margin: 0;
  font-family: "MuseoSansCyrl-900", sans-serif;
  color: #febe14;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 40px;
}

.trip-info-section .trip-nav .trip-info-icons.trip-info-icons li.title-wrapper.reduced-font,
.trip-info-section .trip-nav .trip-sections.trip-info-icons li.title-wrapper.reduced-font {
  font-size: 14px;
}

.trip-info-section .trip-nav .trip-info-icons.trip-sections,
.trip-info-section .trip-nav .trip-sections.trip-sections {
  padding: 3px 0;
}

.trip-info-section .trip-nav .trip-info-icons.trip-sections ul li,
.trip-info-section .trip-nav .trip-sections.trip-sections ul li {
  border-right: 1px solid #fff;
  margin-right: 10px;
  padding-right: 10px;
  display: inline-block;
}

.trip-info-section .trip-nav .trip-info-icons.trip-sections ul li:last-child,
.trip-info-section .trip-nav .trip-sections.trip-sections ul li:last-child {
  border-right: none;
  padding-right: 0;
}

.trip-info-section .trip-nav .trip-info-icons.trip-sections ul li a,
.trip-info-section .trip-nav .trip-sections.trip-sections ul li a {
  color: #fff;
  font-size: 14px;
  font-family: "MuseoSansCyrl-300", sans-serif;
}

@media (max-width: 992px) {
  .trip-info-section {
    padding: 0 10px;
  }

  .trip-info-section .trip-nav .trip-info-icons {
    height: 40px;
    padding: 0;
    min-width: 0;
  }

  .trip-info-section .trip-nav .trip-info-icons .title-wrapper {
    display: none;
  }
}

@media (max-width: 768px) {
  .trip-info-section {
    top: 105px;
  }
}

@media (max-width: 698px) {
  .trip-info-section {
    padding: 0 5px;
  }

  .trip-info-section .trip-nav .trip-info-icons {
    display: none;
  }

  .trip-info-section .trip-nav .trip-sections {
    height: 40px;
    text-align: center;
    width: 0;
    min-width: 0;
  }

  .trip-info-section .trip-nav .trip-sections ul li {
    margin-right: 5px;
    padding-right: 5px;
  }

  .trip-info-section .trip-nav .trip-sections ul li a {
    font-size: 13px;
  }
}

.embedded-view .trip-info-section {
  top: 0 !important;
}

.header-section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-size: cover !important;
  background: no-repeat center center;
}

.header-section .trip-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.timer-section .container {
  z-index: 99;
}

.timer-section .container .section {
  padding: 100px 0;
}

.timer-section .container .section h3 {
  margin-top: 40px;
}

.timer-section .btn-timer {
  background: #f36331;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 20px;
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-family: "MuseoSansCyrl-300", sans-serif;
}

.countdown .measurements {
  flex: 0.1;
  font-size: 18px;
  border-right: 1px dotted #fff;
  padding: 0 40px;
}

.countdown .measurements:last-of-type {
  border: none;
}

.countdown .measurements .measurements-number {
  margin: 0;
  font-size: 44px;
}

.countdown .measurements .measurements-text {
  margin: 0;
}

.countdown .measurements .divider {
  float: right;
}

@media (max-width: 460px) {
  .countdown .measurements {
    padding: 0 10px;
  }
}

.summary-section {
  line-height: 34px;
  font-size: 20px;
}

.summary-section:first-of-type {
  padding-top: 60px;
}

.promo-price {
  color: #f36331;
}

.price-section p {
  margin-bottom: 12px;
  font-size: 20px;
}

.price-section .prices-layout {
  display: flex;
}

.price-section .prices-layout .promo-description {
  background: #ccc;
  padding: 5px;
  font-size: 16px;
}

.price-section .prices-layout .price-value {
  min-width: 120px;
  max-width: 120px;
  border-right: 1px solid #0c1828;
}

.price-section .prices-layout .price-value .disabled-by-promo {
  text-decoration: line-through;
  font-family: "MuseoSansCyrl-500",sans-serif;
  font-size: 20px;
  color: #0c1828;
}

.price-section .prices-layout .price-description {
  flex-grow: 1;
  padding-left: 20px;
}

.price-section .departure {
  background: #2c8fc7;
  color: #fff;
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 0.8em;
  white-space: nowrap;
}

.price-section .price-value {
  color: #2c8fc7;
  font-family: "MuseoSansCyrl-900",sans-serif;
  font-size: 25px;
}

.price-section .collapsable-item {
  margin-bottom: 10px;
  line-height: 44px;
  border-radius: 2px;
  font-size: 16px;
  padding-left: 15px;
  cursor: pointer;
}

.price-section .collapsable-item .fa-check-circle-o {
  display: none;
}

.price-section .collapsable-item.collapsed .fa-times-circle-o {
  display: none;
}

.price-section .collapsable-item.collapsed .fa-check-circle-o {
  display: inline;
}

.hotel-section .hotel-content .price-section {
  padding-top: 50px;
}

.hotel-section .hotel-content .price-section.no-hotels {
  padding-top: 0;
}

.hotel-section .hotel-content .price-section .table-pricing {
  padding-bottom: 30px;
}

.hotel-section .hotel-content .price-section .table-pricing table td {
  padding: 10px;
}

.hotel-section .preview-image {
  padding-bottom: 1px;
}

.hotel-section .preview-image img {
  max-width: 100%;
  width: 100%;
  max-height: 400px;
}

.hotel-section .hotel-thumbs ul {
  list-style: none;
  line-height: 1;
  padding: 0;
  margin: 0;
}

.hotel-section .hotel-thumbs ul li {
  padding: 0;
  display: inline-block;
  height: 50px;
  background-size: cover;
  background-position: center center;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hotel-section .hotel-thumbs ul li {
    width: 33%;
  }
}

.gallery-section {
  padding-left: 0;
  padding-right: 0;
}

.gallery-section .gallery-item {
  position: relative;
  cursor: pointer;
}

.gallery-section .gallery-item a {
  background-size: cover;
  background-position: center center;
  display: block;
  height: 315px;
}

.hotels-navigation {
  margin-bottom: 30px;
  padding-bottom: 40px;
}

.hotels-navigation .swiper-button-next,
.hotels-navigation .swiper-button-prev {
  bottom: 0;
  top: auto;
  background: none;
  font-weight: bold;
  text-transform: uppercase;
  width: 100px;
  height: 30px;
}

.hotels-navigation .swiper-button-next:hover,
.hotels-navigation .swiper-button-prev:hover {
  border: none;
  color: #f36331;
}

.hotels-navigation .hotel-nav-item {
  padding: 10px;
  border: 2px solid #efefef;
  cursor: pointer;
  color: #0c1828;
}

.hotels-navigation .hotel-nav-item.swiper-slide {
  height: auto;
}

.hotels-navigation .hotel-nav-item h2 {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
}

.hotels-navigation .hotel-nav-item:hover {
  padding: 5px;
  color: #f36331;
}

.hotels-navigation .hotel-nav-item img {
  width: 100%;
  aspect-ratio: 3/2;
}

@media (max-width: 768px) {
  .attractions .container {
    width: calc(100% - 30px);
  }
}

.attractions .single-attraction .heading-block {
  position: relative;
}

.attractions .single-attraction .heading-block .p {
  font-size: 20px;
}

.attractions .single-attraction .heading-block .video-btn-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  margin: auto;
  width: 83px;
  height: 83px;
  cursor: pointer;
  display: block;
}

.attractions .single-attraction .heading-block .video-btn-overlay img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 460px) {
  .attractions .single-attraction .heading-block .video-btn-overlay {
    position: relative;
    margin: 0 auto 20px;
  }
}

.attractions .multi-attraction .col-md-4 {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 1.6%;
  padding-right: 1.6%;
}

.contact-form-popup .modal-dialog .modal-body {
  padding: 0;
}

.contact-form-popup .modal-dialog .modal-body .row {
  background: #fff;
}

.contact-form-popup .modal-dialog .modal-body .form-description {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 !important;
}

.contact-form-popup .modal-dialog .modal-body .form-description .content-wrapper {
  width: 100%;
  padding: 40px 30px;
  background: rgba(0, 0, 0, 0.4);
}

.contact-form-popup .modal-dialog .modal-body .form-description h3 {
  color: #ffffff;
  margin-bottom: 50px;
  text-align: center;
  line-height: 26px;
}

.contact-form-popup .modal-dialog .modal-body .form-description .contact-info-section {
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}

.contact-form-popup .modal-dialog .modal-body .form-description .contact-info-section .sub-title {
  margin-bottom: 0;
  font-size: 18px;
}

.contact-form-popup .modal-dialog .modal-body .form-description .contact-info-section .info {
  font-family: 'MuseoSansCyrl-300, sans-serif';
  font-size: 24px;
  margin-top: 5px;
}

.contact-form-popup .modal-dialog .modal-body .form-description .contact-info-section .info a {
  background: forestgreen;
  border-width: 2px;
}

.contact-form-popup .modal-dialog .modal-body .form-description .center-center {
  margin: auto;
  text-align: center;
}

.contact-form-popup .modal-dialog .modal-body .form-description .center-center .btn {
  font-size: 15px;
  color: #fff;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 8.5px 16px;
  margin-top: 10px;
  border: 1px solid #fff;
}

.contact-form-popup .modal-dialog .modal-body .form-description .center-center .btn:hover {
  background-color: #fff;
  color: #000 !important;
}

.contact-form-popup .modal-dialog .modal-body .or-section-circle {
  position: absolute;
  right: -25px;
  background-color: #ee3b24;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: calc(50% - 25px);
  text-align: center;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  font-size: 25px;
  line-height: 48px;
  color: #fff;
  z-index: 999;
}

.contact-form-popup .modal-dialog .modal-body .form-section {
  background: #fff;
  padding: 40px 20px 20px;
  text-align: center;
}

.contact-form-popup .modal-dialog .modal-body .form-section h3 {
  font-size: 18px;
  color: #0c1828;
  line-height: 1.2;
  margin-bottom: 20px;
}

.contact-form-popup .modal-dialog .modal-body .form-section form {
  margin-bottom: 0;
  text-align: left;
  padding: 0 20px;
}

.contact-form-popup .modal-dialog .modal-body .form-section form label {
  display: block;
  margin-bottom: 0;
  font-family: 'MuseoSansCyrl-700, sans-serif';
  font-style: initial;
  color: #0c1828;
  text-transform: uppercase;
}

.contact-form-popup .modal-dialog .modal-body .form-section form input {
  border: 2px solid #ddd;
  width: 100%;
}

.contact-form-popup .modal-dialog .modal-body .form-section form .error {
  display: none;
}

.contact-form-popup .modal-dialog .modal-body .form-section form p {
  margin-bottom: 0;
}

.contact-form-popup .modal-dialog .modal-body .form-section .display-none {
  display: none;
}

.contact-form-popup .modal-dialog .modal-body .form-section .or-section {
  text-align: center;
  color: #ee3b24;
  font-size: 18px;
  margin: 15px 0 10px;
}

.contact-form-popup .modal-dialog .modal-body .form-section .center-center {
  margin: auto;
  text-align: center;
}

.contact-form-popup .modal-dialog .modal-body .form-section .center-center .btn {
  font-size: 15px;
  color: #fff;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 8.5px 16px;
  margin-top: 10px;
  background-color: #ef3b24;
}

.contact-form-popup .modal-dialog .modal-body .close-btn {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ee3b24;
  right: -26px;
  top: -13px;
  text-align: center;
  font-size: 13px;
  font-family: 'MuseoSansCyrl-900, sans-serif';
  line-height: 23px;
  cursor: pointer;
}

.contact-form-popup .modal-dialog .modal-body .close-btn a {
  width: 100%;
  display: inline-block;
  color: #ee3b24;
}

.contact-form-popup .modal-dialog .modal-body .close-btn a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .contact-form-popup .modal-dialog {
    max-width: 400px;
    margin: 20px auto;
  }

  .contact-form-popup .modal-dialog .form-description .or-section-circle {
    right: calc(50% - 25px);
    top: calc(100% - 25px);
  }
}

@media (max-width: 460px) {
  .contact-form-popup .modal-dialog {
    max-width: calc(100% - 50px);
  }
}

.program-section {
  padding: 60px 0 0;
}

.program-section .heading-block {
  margin-bottom: 20px;
}

.program-section .legend .title {
  color: #f36233;
  font-size: 22px;
}

.program-section .legend ul {
  padding: 0;
  margin-top: 30px;
}

.program-section .legend ul li {
  display: inline-block;
  padding: 0 30px;
}

.program-section .legend ul li i {
  display: block;
  font-size: 26px;
  margin-bottom: 10px;
}

.program-section i.activities {
  color: #f36233;
}

.program-section i.excursion {
  color: #1898d5;
}

.program-section i.transfer {
  color: #32c6f4;
}

.program-section i.hotel {
  color: #febe14;
}

.program-section i.meals {
  color: #f35304;
}

.program-section i.attractions {
  color: #1898d5;
}

.program-section .days-list {
  border-bottom: 1px solid #bfbfbf;
}

.program-section .days-list ul {
  list-style: none;
}

.program-section .days-list ul .day-program-container {
  margin-top: 30px;
  vertical-align: top;
  display: inline-block;
  width: 19.5%;
}

.program-section .days-list ul .day-program-container h3 {
  font-size: 20px;
  color: #000;
  margin-bottom: 15px;
}

.program-section .days-list ul .day-program-container h3 span {
  font-size: 14px;
  font-family: "MuseoSansCyrl-300", sans-serif;
  font-weight: bold;
  text-transform: lowercase;
}

.program-section .days-list ul .day-program-container ul {
  padding-left: 0;
}

.program-section .days-list ul .day-program-container ul li {
  padding-left: 20px;
  position: relative;
}

.program-section .days-list ul .day-program-container ul li i {
  position: absolute;
  top: 4px;
  left: 0;
}

.program-section .day-program-gallery .day-program-gallery-item .header h3 {
  font-size: 30px;
  color: #000;
  margin: 30px 0 0;
}

.program-section .day-program-gallery .day-program-gallery-item .header h3 span {
  font-size: 20px;
  font-family: "MuseoSansCyrl-300", sans-serif;
  font-weight: bold;
  text-transform: lowercase;
}

.program-section .day-program-gallery .day-program-gallery-item .header p {
  font-size: 22px;
  color: #888;
}

.program-section .day-program-gallery .day-program-gallery-item .background-image-section {
  min-height: 400px;
  color: #fff;
}

.program-section .day-program-gallery .day-program-gallery-item .background-image-section .overlay {
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.program-section .day-program-gallery .day-program-gallery-item .background-image-section .container {
  z-index: 99;
}

.program-section .day-program-gallery .day-program-gallery-item .background-image-section .container p {
  column-count: 2;
  column-gap: 40px;
  text-align: justify;
}

@media (max-width: 768px) {
  .program-section .day-program-gallery .day-program-gallery-item .background-image-section .container p {
    column-count: 1;
  }
}

@media (max-width: 992px) {
  .program-section .days-list ul .day-program-container {
    width: 33%;
  }
}

@media (max-width: 768px) {
  .program-section .days-list ul .day-program-container {
    width: 48%;
  }

  .program-section .legend li {
    width: 40%;
    padding: 10px 30px;
  }
}

@media (max-width: 460px) {
  .program-section .days-list ul .day-program-container {
    width: 98%;
  }
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  border: 1px solid #ccc;
}

