@import "variables";

.single-adventure {
  position: relative;

  .preloader {
    padding: 150px 0;
  }

  .book-now {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    cursor: pointer;
  }

  .embedded-video {
    max-width: 800px;
    margin: 0 auto;

    iframe {
      width: 90%;
      height: 30em;

      @media (max-width: $small-size) {
        height: 12em;
      }
    }
  }
}

.call-to-action {
  background: #ef3b24;
  padding: 20px 0;

  h3 {
    margin: 0;
    padding: 8px 20px;
    vertical-align: middle;
    line-height: 25px;
    display: inline-block;
    font-size: $fs-xl;
    text-transform: none;
    color: #fff;
  }

  .button {
    background: #fdbf12;
    border: 2px solid #fdbf12;
    color: #0b1828;
    text-shadow: none;
    font-family: "MuseoSansCyrl-900",sans-serif;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    margin: 5px;
    padding: 0 22px;
    font-size: $fs-base;
    line-height: 40px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;

    &:hover {
      text-decoration: none;
      background: #fff;
      border-color: #000;
    }
  }
}

.trip-info-section {
  z-index: 99;
  position: fixed;
  top: 85px;
  left: 0;
  height: 40px;
  width: 100%;
  background: #0c1828;
  padding: 0 30px;

  &.top-hidden {
    top: 0;
  }

  .trip-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .table-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .trip-info-icons, .trip-sections {
      font-size: $fs-base;
      color: #fff;
      vertical-align: middle;

      ul {
        padding-left: 0;
        margin: 0;
        list-style: none;
      }

      i {
        color: #febe14;
        font-size: 17px;
        margin-right: 5px;
      }

      &.trip-info-icons {
        min-width: 614px;
        padding: 0 20px;

        li {
          display: inline-block;
          padding: 0 5px;

          &.title-wrapper {
            height: 40px;
            margin: 0;
            font-family: "MuseoSansCyrl-900", sans-serif;
            color: #febe14;
            text-transform: uppercase;
            font-size: $fs-m;
            line-height: 40px;

            &.reduced-font {
              font-size: $fs-base;
            }
          }
        }
      }

      &.trip-sections {
        padding: 3px 0;

        ul li {
          border-right: 1px solid #fff;
          margin-right: 10px;
          padding-right: 10px;
          display: inline-block;

          &:last-child {
            border-right: none;
            padding-right: 0;
          }

          a {
            color: #fff;
            font-size: $fs-base;
            font-family: "MuseoSansCyrl-300", sans-serif;
          }
        }
      }
    }
  }

  @media (max-width: $medium-size) {
    padding: 0 10px;

    .trip-nav {
      .trip-info-icons {
        height: 40px;
        padding: 0;
        min-width: 0;

        .title-wrapper {
          display: none;
        }
      }
    }
  }

  @media (max-width: $small-size) {
    top: 105px;
  }

  @media (max-width: $small-size - 70) {
    padding: 0 5px;

    .trip-nav {
      .trip-info-icons {
        display: none;
      }

      .trip-sections {
        height: 40px;
        text-align: center;
        width: 0;
        min-width: 0;

        ul li {
          margin-right: 5px;
          padding-right: 5px;

          a {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.embedded-view .trip-info-section {
  top: 0 !important;
}

.header-section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-size: cover !important;
  background: no-repeat center center;

  .trip-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.timer-section {
  .container {
    z-index: 99;

    .section {
      padding: 100px 0;

      h3 {
        margin-top: 40px;
      }
    }
  }

  .btn-timer {
    background: $menu-item-4;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 20px;
  }
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-family: "MuseoSansCyrl-300", sans-serif;

  .measurements {
    flex: 0.1;
    font-size: $fs-l;
    border-right: 1px dotted #fff;
    padding: 0 40px;

    &:last-of-type {
      border: none;
    }

    .measurements-number {
      margin: 0;
      font-size: 44px;
    }

    .measurements-text {
      margin: 0;
    }

    .divider {
      float: right;
    }
  }

  @media (max-width: $tiny-size) {
    .measurements {
      padding: 0 10px;
    }
  }
}

.summary-section {
  line-height: 34px;
  font-size: $fs-l1;

  &:first-of-type {
    padding-top: 60px;
  }
}

.promo-price {
  color: $menu-item-4;
}

.price-section {
  p {
    margin-bottom: 12px;
    font-size: 20px;
  }

  .prices-layout {
    display: flex;

    .promo-description {
      background: #ccc;
      padding: 5px;
      font-size: 16px;
    }

    .price-value {
      min-width: 120px;
      max-width: 120px;
      border-right: 1px solid #0c1828;

      .disabled-by-promo {
        text-decoration: line-through;
        font-family: "MuseoSansCyrl-500",sans-serif;
        font-size: 20px;
        color: #0c1828;
      }
    }

    .price-description {
      flex-grow: 1;
      padding-left: 20px;
    }
  }

  .departure {
    background: #2c8fc7;
    color: #fff;
    border-radius: 15px;
    padding: 3px 10px;
    font-size: 0.8em;
    white-space:nowrap;
  }

  .price-value {
    color: #2c8fc7;
    font-family: "MuseoSansCyrl-900",sans-serif;
    font-size: 25px;
  }

  .collapsable-item {
    margin-bottom: 10px;
    line-height: 44px;
    border-radius: 2px;
    font-size: $fs-m;
    padding-left: 15px;
    cursor: pointer;

    .fa-check-circle-o {
      display: none;
    }

    &.collapsed {
      .fa-times-circle-o {
        display: none;
      }

      .fa-check-circle-o {
        display: inline;
      }
    }
  }
}

.hotel-section {
  .hotel-content {
    .price-section {
      padding-top: 50px;

      &.no-hotels {
        padding-top: 0;
      }

      .table-pricing {
        padding-bottom: 30px;

        table td {
          padding: 10px;
        }
      }
    }
  }

  .preview-image {
    padding-bottom: 1px;

    img {
      max-width: 100%;
      width: 100%;
      max-height: 400px;
    }
  }

  .hotel-thumbs {
    ul {
      list-style: none;
      line-height: 1;
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        display: inline-block;
        height: 50px;
        background-size: cover;
        background-position: center center;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        cursor: pointer;

        @media (max-width: $small-size) {
          width: 33%;
        }
      }
    }
  }
}

.gallery-section {
  padding-left: 0;
  padding-right: 0;

  .gallery-item {
    position: relative;
    cursor: pointer;

    a {
      background-size: cover;
      background-position: center center;
      display: block;
      height: 315px;
    }
  }
}

.hotels-navigation {
  margin-bottom: 30px;
  padding-bottom: 40px;

  .swiper-button-next, .swiper-button-prev {
    bottom: 0;
    top: auto;
    background: none;
    font-weight: bold;
    text-transform: uppercase;
    width: 100px;
    height: 30px;

    &:hover {
      border: none;
      color: $menu-item-4;
    }
  }

  .hotel-nav-item {
    padding: 10px;
    border: 2px solid #efefef;
    cursor: pointer;
    color: #0c1828;

    &.swiper-slide {
      height: auto;
    }

    h2 {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    &:hover {
      padding: 5px;
      color: $menu-item-4;
    }

    img {
      width: 100%;
      aspect-ratio: 3/2;
    }
  }
}

.attractions {
  @media (max-width: $small-size) {
    .container {
      width: calc(100% - 30px);
    }
  }

  .single-attraction {
    .heading-block {
      position: relative;

      .p {
        font-size: $fs-l1;
      }

      .video-btn-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
        margin: auto;
        width: 83px;
        height: 83px;
        cursor: pointer;
        display: block;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      @media (max-width: $tiny-size) {
        .video-btn-overlay {
          position: relative;
          margin: 0 auto 20px;
        }
      }
    }
  }

  .multi-attraction {
    .col-md-4 {
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 1.6%;
      padding-right: 1.6%;
    }
  }
}

.contact-form-popup {
  .modal-dialog {
    .modal-body {
      padding: 0;

      .row {
        background: #fff;
      }

      .form-description {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0 !important;

        .content-wrapper {
          width: 100%;
          padding: 40px 30px;
          background: rgba(0, 0, 0, 0.4);
        }

        h3 {
          color: #ffffff;
          margin-bottom: 50px;
          text-align: center;
          line-height: 26px;
        }

        .contact-info-section {
          color: #fff;
          text-align: center;
          margin-bottom: 50px;

          .sub-title {
            margin-bottom: 0;
            font-size: 18px;
          }

          .info {
            font-family: 'MuseoSansCyrl-300, sans-serif';
            font-size: 24px;
            margin-top: 5px;

            a {
              background: forestgreen;
              border-width: 2px;
            }
          }
        }

        .center-center {
          margin: auto;
          text-align: center;

          .btn {
            font-size: 15px;
            color: #fff;
            font-family: 'MuseoSansCyrl-900, sans-serif';
            text-transform: uppercase;
            letter-spacing: 1.5px;
            padding: 8.5px 16px;
            margin-top: 10px;
            border: 1px solid #fff;

            &:hover {
              background-color: #fff;
              color: #000 !important;
            }
          }
        }
      }

      .or-section-circle {
        position: absolute;
        right: -25px;
        background-color: #ee3b24;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        top: calc(50% - 25px);
        text-align: center;
        font-family: 'MuseoSansCyrl-900, sans-serif';
        font-size: 25px;
        line-height: 48px;
        color: #fff;
        z-index: 999;
      }

      .form-section {
        background: #fff;
        padding: 40px 20px 20px;
        text-align: center;

        h3 {
          font-size: 18px;
          color: #0c1828;
          line-height: 1.2;
          margin-bottom: 20px;
        }

        form {
          margin-bottom: 0;
          text-align: left;
          padding: 0 20px;

          label {
            display: block;
            margin-bottom: 0;
            font-family: 'MuseoSansCyrl-700, sans-serif';
            font-style: initial;
            color: #0c1828;
            text-transform: uppercase;
          }

          input {
            border: 2px solid #ddd;
            width: 100%;
          }

          .error {
            display: none;
          }

          p {
            margin-bottom: 0;
          }
        }

        .display-none {
          display: none;
        }

        .or-section {
          text-align: center;
          color: #ee3b24;
          font-size: 18px;
          margin: 15px 0 10px;
        }

        .center-center {
          margin: auto;
          text-align: center;

          .btn {
            font-size: 15px;
            color: #fff;
            font-family: 'MuseoSansCyrl-900, sans-serif';
            text-transform: uppercase;
            letter-spacing: 1.5px;
            padding: 8.5px 16px;
            margin-top: 10px;
            background-color: #ef3b24;
          }
        }
      }

      .close-btn {
        position: absolute;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid #ee3b24;
        right: -26px;
        top: -13px;
        text-align: center;
        font-size: 13px;
        font-family: 'MuseoSansCyrl-900, sans-serif';
        line-height: 23px;
        cursor: pointer;

        a {
          width: 100%;
          display: inline-block;
          color: #ee3b24;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (max-width: $small-size) {
    .modal-dialog {
      max-width: 400px;
      margin: 20px auto;

      .form-description {
        .or-section-circle {
          right: calc(50% - 25px);
          top: calc(100% - 25px);
        }
      }
    }
  }

  @media (max-width: $tiny-size) {
    .modal-dialog {
      max-width: calc(100% - 50px);
    }
  }
}

.program-section {
  padding: 60px 0 0;

  .heading-block {
    margin-bottom: 20px;
  }

  .legend {
    .title {
      color: #f36233;
      font-size: $fs-xl;
    }

    ul {
      padding: 0;
      margin-top: 30px;

      li {
        display: inline-block;
        padding: 0 30px;

        i {
          display: block;
          font-size: $fs-xxl;
          margin-bottom: 10px;
        }
      }
    }
  }

  i {
    &.activities { color: #f36233; }
    &.excursion { color: #1898d5; }
    &.transfer { color: #32c6f4; }
    &.hotel { color: #febe14; }
    &.meals { color: #f35304; }
    &.attractions { color: #1898d5; }
  }

  .days-list {
    border-bottom: 1px solid #bfbfbf;

    ul {
      list-style: none;

      .day-program-container {
        margin-top: 30px;
        vertical-align: top;
        display: inline-block;
        width: 19.5%;

        h3 {
          font-size: $fs-l1;
          color: #000;
          margin-bottom: 15px;

          span {
            font-size: $fs-base;
            font-family: "MuseoSansCyrl-300", sans-serif;
            font-weight: bold;
            text-transform: lowercase;
          }
        }

        ul {
          padding-left: 0;

          li {
            padding-left: 20px;
            position: relative;

            i {
              position: absolute;
              top: 4px;
              left: 0;
            }
          }
        }
      }
    }
  }

  .day-program-gallery {
    .day-program-gallery-item {
      .header {
        h3 {
          font-size: 30px;
          color: #000;
          margin: 30px 0 0;

          span {
            font-size: $fs-l1;
            font-family: "MuseoSansCyrl-300", sans-serif;
            font-weight: bold;
            text-transform: lowercase;
          }
        }

        p {
          font-size: $fs-xl;
          color: #888;
        }
      }

      .background-image-section {
        min-height: 400px;
        color: #fff;

        .overlay {
          min-height: 400px;
          width: 100%;
          display: flex;
          align-items: center;
          background: rgba(0, 0, 0, 0.7);
        }

        .container {
          z-index: 99;

          p {
            column-count: 2;
            column-gap: 40px;
            text-align: justify;

            @media (max-width: $small-size) {
              column-count: 1;
            }
          }
        }
      }
    }
  }

  @media (max-width: $medium-size) {
    .days-list ul {
      .day-program-container {
        width: 33%;
      }
    }
  }

  @media (max-width: $small-size) {
    .days-list ul {
      .day-program-container {
        width: 48%;
      }
    }

    .legend {
      li {
        width: 40%;
        padding: 10px 30px;
      }
    }
  }

  @media (max-width: $tiny-size) {
    .days-list ul {
      .day-program-container {
        width: 98%;
      }
    }
  }
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-next, .swiper-button-prev {
  background-color: rgba(0,0,0,0.3);
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  border: 1px solid #ccc;
}